@import url(https://fonts.googleapis.com/css?family=Ewert);
@import url(https://fonts.googleapis.com/css?family=Ultra);

html,
body,
#root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
canvas,
.heatmap {
  pointer-events: none;
}

.leaflet-container {
  background-color: rgb(213, 182, 142) !important;
}

[disabled] {
  opacity: 0.6;
}
